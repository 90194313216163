import React from 'react';
import {Link } from 'gatsby'
import Layout from '@components/Layout';
import ContentNarrow from '@components/ContentNarrow';

const page404 = ({ data }) => {
  return (
    <Layout>
      <ContentNarrow>
        <h1>404 - Oh no!  This page could not be found.</h1>
        <p>
          <Link to="/">Return to the homepage</Link>
        </p>
      </ContentNarrow>
    </Layout>
  )
};

export default page404;
